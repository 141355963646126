export default {
    'email' : 'EmailPrivacy',
    'info-prestiti': 'LoanInformation',
    'info-prestiti-error': 'LoanInformationError',
    'rata': 'RatePersonalCredit',
    'rata-upgrade': 'RatePersonalCredit',
    'contatti': 'Contacts',
    'anagrafica': 'PersonalData',
    'reddito': 'IncomeData',
    'lavoro': 'EmployerData',
    'assicurazione': 'LoanProtection',
    'assicurazione-consenso': 'LoanProtectionConsent',
    'assicurazione-avviso': 'LoanProtectionWarning',
    'residenza': 'DeliveryData',
    'documento': 'IdentityDocData',
    'iban': 'PaymentData',
    'typ': 'Typ',
    'typ-ko': 'TypKO',
    'soluzioni': "Solutions"
}
